<template>
  <div class="main-tips">
    <TipsPremium v-if="isPremiumUser" />
    <TipsFree v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Main',
  components: {
    TipsFree: () => import('@/components/Sections/GolTipster-v2/Tips/TipsFree'),
    TipsPremium: () => import('@/components/Sections/GolTipster-v2/Tips/TipsPremium'),
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'isLoggedIn']),
    isPremiumUser() {
      return this.isLoggedIn && this.getPremiumAccount;
    },
  },
  watch: {},
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="scss"></style>
